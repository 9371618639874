import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    onresizeNumber: 0,
    lbTime: 0,
    int:false
  },
  mutations: {
    SET_onresizeNumber: (state, onresizeNumber) => state.onresizeNumber = onresizeNumber,
    SET_lbTime: (state, lbTime) => state.lbTime = lbTime,
  },
  actions: {},
  modules: {},
});
