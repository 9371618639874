import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home.vue"),
    // redirect: "/page1"
  },
  {
    path: "/page1",
    name: "page1",
    component: () => import("@/views/page1.vue"),
  },
  {
    path: "/page2",
    name: "page2",
    component: () => import("@/views/page2.vue"),
  },
  {
    path: "/page3",
    name: "page3",
    component: () => import("@/views/page3.vue"),
  },
  {
    path: "/page4",
    name: "page4",
    component: () => import("@/views/page4.vue"),
  },
  {
    path: "/page5",
    name: "page5",
    component: () => import("@/views/page5.vue"),
  },
  {
    path: "/page6",
    name: "page6",
    component: () => import("@/views/page6.vue"),
  }
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (process.env.NODE_ENV==='test'&& localStorage.getItem("pwd") !== 'zm') {
    let pwd = prompt("请输入密码")
    if (pwd === 'zm') {
      localStorage.setItem("pwd", "zm")
    } else {
      Vue.$toast.error(`密码错误，请联系管理员`)
      throw '密码错误'
    }
  }
  next()
})


export default router;
