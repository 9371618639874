<template>
    <!-- <keep-alive> -->
    <div class="routerView">
        <router-view></router-view>
        <div class="number" v-if="$store.state.lbTime">{{$store.state.lbTime}}</div>
    </div>
    <!-- </keep-alive> -->
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        mounted() {
            console.log(1);
        }
    }
</script>
<style scoped lang="less">
    .routerView {
        position: relative;
        width: 100%;
        height: 100%;

        .number {
            position: absolute;
            right: 5px;
            bottom: 5px;
            color: rgba(255, 255, 255, 0.2);
            font-size: 0.14rem;
        }
    }
</style>